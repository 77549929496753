<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Users Roles</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                @click="openFormRole = true">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <role-list :rolesData="roles.roles" />
    <v-dialog
      v-model="openFormRole"
      persistent
      max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create User Role</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="role.name"
              label="Role name"
              outlined
              required />

            <h3>Role Condition</h3>
            <v-row>
              <v-col class="roleTable"
                     cols="4">
                Application Name
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                Create
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                Edit
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                View
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                Cancle
              </v-col>
            </v-row>
            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Job Management
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.create"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.edit"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.cancle"
                            dense />
              </v-col>
            </v-row>

            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Customer
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="customer.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
            </v-row>

            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Report
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="report.create"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="report.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
            </v-row>

            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Administrators
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.create"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.edit"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.cancle"
                            dense />
              </v-col>
            </v-row>

            <v-checkbox
              v-model="role.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="create">
            Create
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormRole = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Roles',
  components: {
    RoleList: () => import('@/components/Role/roleList.vue'),
  },
  data() {
    return {
      submenu: [
        { text: 'Create Role', icon: 'mdi-folder-account-outline' },
      ],
      openFormRole: false,
      role: {
        name: '',
        scopes: [],
        activated: true,
      }
    };
  },

  async created() {
    await this.$store.dispatch('userRole/getAllRoles');
  },

  computed: {
    ...mapGetters({
      roles: 'userRole/GET_ROLES'
    })
  },

  methods: {
    async create() {
      await this.$store.dispatch('userRole/createUserRole', this.role);

      this.role.name = '';
      this.scopes = [];
      this.role.activated = true;

      this.openFormRole = false;
    }
  }
};
</script>

<style>
</style>
